import { fetcher } from '@lib/fetcher';

import { SEND_EMAIL_TO_ORG } from './SendMessageToOrg.mutation';
import { transformSendEmailToOrgData } from './SendMessageToOrg.transform';
import { Variables } from './types';

export default function useSendMessageToOrg() {
    function mutate(variables: Variables) {
        return fetcher(SEND_EMAIL_TO_ORG, variables).then((data) => {
            return transformSendEmailToOrgData(data);
        });
    }

    return { mutate };
}
