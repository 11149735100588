import { Field, Form, Formik } from 'formik';
import { PageData, SEO } from '@data/strapi/Common/Types';
import { useEffect, useState } from 'react';

import Button from '@components/atoms/Button/Button';
import Chip from '@components/atoms/Chip/Chip';
import CountryList from '@components/common/CountryList/CountryList';
import DisplayImage from '@components/atoms/DisplayImage/DisplayImage';
import ErrorMessage from '@components/atoms/Input/ErrorMessage/ErrorMessage';
import { GET_ORGANIZATION } from '@data/backoffice/Organization/Query/GetOrganization/GetOrganizationQuery.query';
import Head from 'next/head';
import Heading from '@components/atoms/Heading/Heading';
import ImageWrapper from '@components/atoms/ImageWrapper/ImageWrapper';
import InputWrapper from '@components/atoms/Input/InputWrapper/InputWrapper';
import Link from 'next/link';
import RichText from '@components/atoms/RichText/RichText';
import ShareModal from '@components/molecules/ShareModal/ShareModal.component';
import _ from 'lodash';
import classNames from 'classnames';
import { createSeo } from '@constants/utils';
import { fetcher } from '@lib/fetcher';
import { setHttps } from '@lib/utils';
import styles from '@styles/pages/Partner.module.scss';
import { useAuth } from '@providers/AuthProvider/AuthProvider';
import useSendMessageToOrg from '@data/backoffice/Organization/Mutation/SendMessageToOrg/SendMessageToOrg';
import useTranslation from 'next-translate/useTranslation';

export default function Partner(params: {
  initialData: PageData;
  variables: any;
  slug: string;
}) {
  const { t } = useTranslation('common')
  const { me, canLoad, loadingMe, logout } = useAuth() as any;
  const [message, setMessage] = useState<string | null>(null);
  const [errors, setErrors] = useState<string | null>();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const [updatedValues, setUpdatedValues] = useState<any>({});
  const { mutate } = useSendMessageToOrg();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const initialValues = {
    message: '',
  };

  const post = params?.initialData?.partner;

  const imageUrl = post?.profilePhoto;
  const imageAlt = post?.legalName;


  const seo = {
    metaTitle: post?.legalName,
    metaDescription: post?.statement,
    metaImage: post?.profilePhoto,
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setSuccessMessage(null);
    }, 3000);

    return () => clearTimeout(timeOut);
  }, [successMessage]);

  let statusColor, statusLabel;
  switch (post?.status?.code) {
    case 'prime':
      statusColor = '#339BED';
      statusLabel = t("partners.prime_partner");
      break;
    case 'prime_past':
      statusColor = '#A7C6ED';
      statusLabel = t("partners.prime_past_partner");
      break;
    case 'sub_contractor':
      statusColor = '#4a2428';
      statusLabel = t("partners.sub_partner");
      break;
    case 'sub_contractor_past':
      statusColor = '#E58F9A';
      statusLabel = t("partners.sub_past_partner");
      break;
    default:
      statusColor = '#FFC640';
      statusLabel = t("partners.not_partner");
      break;
  }

  return (
    <>
      <Head>{createSeo(seo as SEO)}</Head>
      <div className="container mt-md-4 mb-5">
        <div className="row mb-5">
          <div className="column col-12">
            <ul className="d-flex nav-container align-items-center">
              <li>
                <Link href={'/partner-directory'} legacyBehavior>
                  <Button
                    variation="link"
                    label={t("collections.partners")}
                    url="/partner-directory"
                    className="color-primary-100 fw-semi_bold m-0"
                  ></Button>
                </Link>
              </li>
              <li>
                <i className="icon-right" />
              </li>
              <li>
                <Link href={`/partner-directory/${post?.slug}`} legacyBehavior>
                  <Button
                    variation="link"
                    label={post?.legalName}
                    url={`/partner-directory/${post?.slug}`}
                    className="fw-semi_bold m-0"
                  ></Button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="column col-lg-3 col-md-12 mb-5">
            <div className={styles.partnerThumbnail}>
              <ImageWrapper aspectRatioHeight={28}>
                <DisplayImage
                  fill={true}
                  src={imageUrl && imageUrl}
                  alt={imageAlt || post?.title}
                />
              </ImageWrapper>
            </div>
            {me && (
              <div className="mt-3 mb-3">
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={async (values, actions) => {
                    const { result } = await mutate({
                      content: values.message as string,
                      id: post.id,
                    });
                    if (result.code != 'EMAIl_SENT_WITH_SUCCESS') {
                      setErrors(result.label);
                    } else {
                      setSuccessMessage(t("partners.form_success"));
                      setUpdatedValues({});
                      actions.resetForm({});
                    }
                  }}
                >
                  {({ isValid, values, setFieldValue }) => {
                    return (
                      <Form>
                        <Field
                          size={'small'}
                          name="message"
                          id="message"
                          identifier="message"
                          placeholder={t("contacts.msg_placeholder")}
                          fullWidth={true}
                          onChange={(e: any) => {
                            setFieldValue('message', e.target.value);
                            setErrors(null);
                          }}
                          error={!isValid}
                          as="textarea"
                        />
                        {successMessage && <p>{successMessage}</p>}
                        {errors && <ErrorMessage errorMessage={errors} />}
                        <div
                          className={
                            styles[
                              'customized-guidance-report-share-email__footer'
                            ]
                          }
                        >
                          <Button
                            className="w-100 mt-3"
                            submitButton={true}
                            label={t("contacts.buttonText")}
                            disabled={values?.message === ''}
                          />
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            )}
          </div>
          <div className="column col-lg-1 col-md-12"></div>
          <div className="column col-lg-8 col-md-12">
            <div className={styles.status}>
              <span style={{ backgroundColor: statusColor }}></span>
              <RichText
                tag="p"
                value={statusLabel}
                className="color-primary-100 fw-semi_bold"
              ></RichText>
            </div>
            <Heading value={post?.legalName} size="h2" className="mb-4" />
            {post?.sectors && post?.sectors.length > 0 && (
              <div className={styles.chipGroup}>
                <Heading value={t("collections.sectors")} size="h5" />
                <div className={styles.chipContainer}>
                  {post?.sectors.map((sector, index) => {
                    return (
                      <Chip
                        key={`sector-${index}`}
                        className={'d-table fw-semi_bold mb-2'}
                      >
                        {sector.label}
                      </Chip>
                    );
                  })}
                </div>
              </div>
            )}

            {post?.type && post?.type.length > 0 && (
              <div className={styles.chipGroup}>
                <Heading value={t("collections.type_org")} size="h5" />
                <div className={styles.chipContainer}>
                  {post?.type.map((sector, index) => {
                    return (
                      <Chip
                        key={`sector-${index}`}
                        className={'d-table fw-semi_bold mb-2'}
                      >
                        {sector.label}
                      </Chip>
                    );
                  })}
                </div>
              </div>
            )}

            {post?.factors && post?.factors.length > 0 && (
              <div className={styles.chipGroup}>
                <Heading value={t("collections.eco_factors")} size="h5" />
                <div className={styles.chipContainer}>
                  {post?.factors.map((sector, index) => {
                    return (
                      <Chip
                        key={`sector-${index}`}
                        className={'d-table fw-semi_bold mb-2'}
                      >
                        {sector.label}
                      </Chip>
                    );
                  })}
                </div>
              </div>
            )}

            {post?.headquarter.country && (
              <div className="d-flex mb-3">
                <div className="col-lg-6 col-sm-12">
                  <div className={styles.chipGroup}>
                    <Heading value={t("partners.based_in")} size="h5" />
                    <CountryList
                      countries={[post?.headquarter.country]}
                      extraClass={'mt-2'}
                    />
                  </div>
                </div>
                {post?.countryOfOperations.length > 0 && (
                  <div className="col-lg-6 col-sm-12">
                    <div className={styles.chipGroup}>
                      <Heading value={t("partners.operates_in")} size="h5" />
                      <div className="d-flex align-items-start flex-wrap">
                        <CountryList
                          multiple={post?.countryOfOperations.length > 5}
                          countries={post?.countryOfOperations}
                          extraClass={''}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {post?.statement && (
              <div className={styles.statement}>
                <p>{post?.statement}</p>
              </div>
            )}

            {(post?.facebook ||
              post?.linkedIn ||
              post?.website ||
              post?.instagram ||
              post?.twitter) && (
              <div className={classNames(styles.socialsWrapper)}>
                <Heading value={t("common.know_more")} size="h5" />
                <div className={classNames(styles.socials)}>
                  {post?.linkedIn && (
                    <Link
                      target="_blank"
                      referrerPolicy="no-referrer"
                      href={setHttps(post?.linkedIn)}
                      className={styles.social}
                    >
                      <i className="icon-linkedin "></i>
                    </Link>
                  )}

                  {post?.facebook && (
                    <Link
                      target="_blank"
                      referrerPolicy="no-referrer"
                      href={setHttps(post?.facebook)}
                      className={styles.social}
                    >
                      <i className="icon-facebook"></i>
                    </Link>
                  )}

                  {post?.website && (
                    <Link
                      target="_blank"
                      referrerPolicy="no-referrer"
                      href={setHttps(post?.website)}
                      className={styles.social}
                    >
                      <i className="icon-online"></i>
                    </Link>
                  )}

                  {post?.instagram && (
                    <Link
                      target="_blank"
                      referrerPolicy="no-referrer"
                      href={setHttps(post?.instagram)}
                      className={styles.social}
                    >
                      <i className="icon-instagram"></i>
                    </Link>
                  )}

                  {post?.twitter && (
                    <Link
                      target="_blank"
                      referrerPolicy="no-referrer"
                      href={setHttps(post?.twitter)}
                      className={styles.social}
                    >
                      <i className="icon-twitter"></i>
                    </Link>
                  )}
                </div>
              </div>
            )}

            <ShareModal
              siteTitle={'Work With USAID'}
              title={post?.title}
              url={`${process.env.NEXT_PUBLIC_ORIGIN_URL}/blog/${post?.slug}`}
              label={t('blog.share_button')}
              copyText={t('blog.share_copied')}
              open={isOpen}
              onClose={() => setIsOpen(false)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export async function getServerSideProps({ params, query, res }) {
  // cache the page for 10 min, refresh every minute
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=60, stale-while-revalidate=600'
  );

  const variables: any = { where: { slug: params.slug[0] } };

  const initialData = await fetcher(GET_ORGANIZATION, variables);

  if (!initialData?.partner?.slug) {
    return { notFound: true };
  }

  return {
    props: {
      initialData,
      variables,
      slug: params.slug,
    },
  };
}
