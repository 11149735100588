export const SEND_EMAIL_TO_ORG = /* GraphQL */ `
    mutation sendEmailToOrg($id: String, $content: String) {
        sendEmailToOrganization(
            where: { id: $id }
            data: { content: $content }
        ) {
            code
            label
        }
    }
`;
