import React, { useEffect, useState } from "react";

import Heading from "../../atoms/Heading";
import { IShareModal } from "./ShareModal.interface";
import { Modal } from "../Modal";
import cn from "classnames";
import styles from "./ShareModal.module.scss";

type mapTypes = {
  [key: string]: string;
};

const ShareModal = ({
  siteTitle,
  title,
  label,
  url,
  copyText,
  open,
  onClose,
  ...props
}: IShareModal) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setCopied(false);
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [copied]);

  const escapeHtml = (text: string) => {
    if (!text) return "";

    const map: mapTypes = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&#039;",
    };

    return text?.replace(/[&<>"']/g, function (m: string) {
      return map[m];
    });
  };

  const copyToClipboard = (text?: string) => {
    text && navigator.clipboard.writeText(text);
  };

  if (!url) return <></>;

  url = url.replace(/([^:]\/)\/+/g, "$1");

  const shareTitle = title && encodeURIComponent(escapeHtml(title));
  const shareUrl = url && encodeURIComponent(url);

  const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&t=${shareTitle}`;
  const twitterURL = `https://twitter.com/intent/tweet?text=${shareTitle}&url=${shareUrl}`;
  const linkedInURL = `https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${shareTitle}`;
  const whatsappURL = `https://api.whatsapp.com/send?text=${shareUrl}`;
  const emailURL = `mailto:?subject=${siteTitle} - ${shareTitle}&body=${shareUrl}`;

  if (!open) return <></>;

  return (
      <Modal
          title={label}
          handleCancel={() => {
              onClose && onClose();
          }}
      >
          <div className={styles.modal} {...props}>
              <div className="d-flex align-items-center justify-space-between">
                  <a
                      className="social-link"
                      data-social="facebook"
                      aria-label="Facebook"
                      href={facebookURL}
                      target="_blank"
                      rel="noreferrer"
                  >
                      <div>
                          <i className="icon-facebook" />
                      </div>
                      <Heading
                          size={'h6'}
                          semantic={'span'}
                          value={'Facebook'}
                          className="mt-4"
                      />
                  </a>
                  <a
                      className="social-link"
                      data-social="twitter"
                      href={twitterURL}
                      target="_blank"
                      aria-label="Twitter"
                      rel="noreferrer"
                  >
                      <div>
                          <i className="icon-twitter" />
                      </div>
                      <Heading
                          size={'h6'}
                          semantic={'span'}
                          value={'Twitter'}
                          className="mt-4"
                      />
                  </a>
                  <a
                      className="social-link"
                      data-social="linkedin"
                      href={linkedInURL}
                      target="_blank"
                      aria-label="Linked In"
                      rel="noreferrer"
                  >
                      <div>
                          <i className="icon-linkedin" />
                      </div>
                      <Heading
                          size={'h6'}
                          semantic={'span'}
                          value="LinkedIn"
                          className="mt-4"
                      />
                  </a>
                  <a
                      className="social-link"
                      data-social="whatsapp"
                      href={whatsappURL}
                      target="_blank"
                      aria-label="WhatsApp"
                      rel="noreferrer"
                  >
                      <div>
                          <i className="icon-whatsapp" />
                      </div>
                      <Heading
                          size={'h6'}
                          semantic={'span'}
                          value="WhatsApp"
                          className="mt-4"
                      />
                  </a>
                  <a
                      className="social-link"
                      data-social="email"
                      href={emailURL}
                      target="_blank"
                      aria-label="Email"
                      rel="noreferrer"
                  >
                      <div>
                          <i className="icon-email" />
                      </div>
                      <Heading
                          size={'h6'}
                          semantic={'span'}
                          value="Email"
                          className="mt-4"
                      />
                  </a>
              </div>
              <div className="mt-3">
                  <div
                      className={styles.input}
                      onClick={() => {
                          copyToClipboard(url);
                          setCopied(true);
                      }}
                  >
                      <span>{url}</span>
                      <i className="icon-link" />
                  </div>
                  <div className={styles.messageContainer}>
                      <div
                          className={cn(
                              styles.message,
                              'h4',
                              copied ? styles.active : ''
                          )}
                          style={{ textAlign: 'center' }}
                      >
                          {copyText}
                      </div>
                  </div>
              </div>
          </div>
      </Modal>
  );
};

export default ShareModal;
