import Image from "next/image";
import _ from "lodash";
import cn from "classnames";
import styles from "./CountryList.module.scss";
import useTranslation from "next-translate/useTranslation";

const CountryList = ({ countries, limit=100, multiple = false, extraClass, hasLabel  = true}) => {
  const { t } = useTranslation('common');

  return countries && countries.length > 0 ? (
    <div className={cn(styles.flags, extraClass, {})}>
      {countries.length > 1 && multiple ? (
        <div className={styles.flag} key={"multiple-coutries"}>
          <Image
            alt={t("collections.multiple_countries")}
            src={`/assets/flags/multiple.svg`}
            width="16"
            height="16"
          />

          <p>{t("collections.multiple_countries")}</p>
        </div>
      ) : (
        _.chain(countries)
          .slice(0, limit)
          .map((country: any) => (
            <div className={styles.flag} key={country?.code}>
              <Image
                alt={country?.label}
                src={`/assets/flags/${country?.code}.svg`}
                width="16"
                height="16"
              />

              {hasLabel && <p> {country?.label}</p>}
            </div>
          ))
          .value()
      )}
    </div>
  ) : (
    <></>
  );
};

export default CountryList;
